import React, { useState } from "react";
import { Box, Typography, Button, Divider } from "@mui/material";
import { NavLink, useLocation } from "react-router-dom";
import { DashboardSideMenu } from "../../../static/staticdata";
import { pageScrollStyle } from "../../../styles/styles";
import KeyboardArrowLeftRoundedIcon from "@mui/icons-material/KeyboardArrowLeftRounded";
import ChevronRightRoundedIcon from "@mui/icons-material/ChevronRightRounded";

const SideNavBar = () => {
  const location = useLocation();
  const [openSide, setOpenSide] = useState(false);

  const handleOpenSlide = () => {
    console.log("clicked");
    setOpenSide(!openSide);
  };
  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "start",
          alignItems: "center",
          height: "100vh",
          px: openSide ? "0.5rem" : "1rem",
          py: "1rem",
          position: "relative",
          bgcolor: "#fff",
          width: openSide ? "6rem" : "20%",
          minWidth: openSide ? "none" : "14rem",
          borderTopRightRadius: "1.5rem",
          boxShadow: "0px 0px 1rem #00b3b06d",
          gap: "0.5rem",
          // transition: "all 0.3ms ",
        }}
      >
        <Box>
          <Button
            variant="outlined"
            color="primary"
            onClick={handleOpenSlide}
            fullWidth
            sx={{
              width: openSide ? "2rem" : "100%",
              minHeight: "2.5rem",
              borderRadius: "0.625rem",
              // transition: "all 0.3s ",
            }}
          >
            {openSide ? (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <ChevronRightRoundedIcon />
              </Box>
            ) : (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: "0.5rem",
                }}
              >
                <KeyboardArrowLeftRoundedIcon />
                Collabe
              </Box>
            )}
          </Button>
        </Box>
        <Divider orientation="horizontal" flexItem />
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: "0.5rem",
            overflowY: "auto",
            height: "75%",
            p: "0.5rem",
            // transition: "all 0.3s ",
            ...pageScrollStyle,
          }}
        >
          {DashboardSideMenu?.map((item, i) => {
            const isActive = location.pathname === item?.path;
            return (
              <NavLink
                key={i}
                to={item?.path}
                style={{
                  display: "flex",
                  justifyContent: "start",
                  alignItems: "center",
                  textDecoration: "none",
                  backgroundColor: isActive ? "#00B3B0" : "transparent",
                  color: isActive ? "#fff" : "#00B3B0",
                  width: openSide ? "3rem" : "100%",
                  minHeight: "2.5rem",
                  borderRadius: "0.4rem",
                  overflow: "hidden",
                  transition: "all 0.3ms ",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: "0.5rem",
                    px: "0.5rem",
                    pl: "1rem",
                  }}
                >
                  {item?.mIcon}
                  <Typography
                    variant="body1"
                    sx={{
                      fontSize: "1rem",
                      fontWeight: isActive ? 500 : 400,
                      opacity: openSide ? 0 : 1,
                      // transition: "opacity 0.5s ",
                    }}
                  >
                    {item?.mText}
                  </Typography>
                </Box>
              </NavLink>
            );
          })}
        </Box>
      </Box>
    </>
  );
};

export default SideNavBar;
