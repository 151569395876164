import React from "react";
import { Box, Typography, Grid, Card, CardContent } from "@mui/material";
import LandingImg from "../../assets/landing-page.webp";

const HomePage = () => {
  return (
    <>
      <Box sx={{ backgroundColor: "#f4f4f4", minHeight: "100vh", py: 4 }}>
        <Box
          sx={{
            maxWidth: "75rem",
            mx: "auto",
            px: 2,
          }}
        >
          {/* Hero Section */}
          <Grid container spacing={4} alignItems="center" sx={{ mb: 8 }}>
            <Grid item xs={12} md={6}>
              <Typography
                variant="body1"
                color="primary"
                sx={{ fontSize: "1rem" }}
                gutterBottom
              >
                Intellectual Property Optimized
              </Typography>

              <Typography
                variant="h3"
                color="text.primary"
                sx={{ fontWeight: "bold", mb: 2 }}
              >
                Everything your law firm needs. All in one place.
              </Typography>

              <Typography variant="body1" color="text.secondary" sx={{ mb: 3 }}>
                Simplify every aspect of your law firm, from billing to
                communication and document management.
              </Typography>
            </Grid>

            <Grid item xs={12} md={6}>
              <Box sx={{ height: "80%" }}>
                <img
                  src={LandingImg}
                  alt="Company Logo"
                  style={{
                    height: "100%",
                    width: "100%",
                    objectFit: "contain",
                    filter: "drop-shadow(0rem 1rem 0.5rem #00000050)",
                  }}
                  onError={(e) => {
                    e.onerror = null;
                    e.target.src = require("../../assets/no-preview.webp");
                  }}
                />
              </Box>
            </Grid>
          </Grid>

          {/* About Section */}
          <Box sx={{ mb: 8 }}>
            <Typography
              variant="h4"
              align="center"
              color="text.primary"
              sx={{ mb: 4, fontWeight: "bold" }}
            >
              Why Our Agency
            </Typography>

            <Grid container spacing={4}>
              <Grid item xs={12} md={6}>
                <Typography
                  variant="body1"
                  color="text.secondary"
                  sx={{ mb: 2, fontSize: "0.9rem" }}
                >
                  Our agency is built on a foundation of legal expertise and a
                  deep understanding of the complexities of the legal industry.
                  We specialize in providing tailored solutions to help legal
                  professionals streamline their practices and achieve better
                  results for their clients.
                </Typography>
              </Grid>

              <Grid item xs={12} md={6}>
                <Grid container spacing={2}>
                  {[
                    { title: "9875", subtitle: "Satisfied Clients" },
                    { title: "7894", subtitle: "Project Launched" },
                    { title: "65", subtitle: "Years Completed" },
                  ].map((stat, index) => (
                    <Grid item xs={4} key={index}>
                      <Card variant="outlined">
                        <CardContent sx={{ textAlign: "center" }}>
                          <Typography variant="h5" color="primary">
                            {stat.title}
                          </Typography>
                          <Typography variant="body2" color="text.secondary">
                            {stat.subtitle}
                          </Typography>
                        </CardContent>
                      </Card>
                    </Grid>
                  ))}
                </Grid>
              </Grid>
            </Grid>
          </Box>

          {/* Services Section */}
          <Box sx={{ mb: 8 }}>
            <Typography
              variant="h4"
              align="center"
              color="text.primary"
              sx={{ mb: 4, fontWeight: "bold" }}
            >
              Our Specialization
            </Typography>

            <Grid container spacing={3}>
              {[
                {
                  icon: "🔭",
                  title: "Strategy & Research",
                  description:
                    "Mauris ut felis malesuada eros varius tristique a at orci.",
                },
                {
                  icon: "💻",
                  title: "Web Development",
                  description:
                    "Mauris ut felis malesuada eros varius tristique a at orci.",
                },
                {
                  icon: "🌐",
                  title: "Web Solution",
                  description:
                    "Mauris ut felis malesuada eros varius tristique a at orci.",
                },
              ].map((service, index) => (
                <Grid item xs={12} md={4} key={index}>
                  <Card variant="outlined" sx={{ height: "100%" }}>
                    <CardContent sx={{ textAlign: "center" }}>
                      <Typography variant="h2" sx={{ mb: 2 }}>
                        {service.icon}
                      </Typography>
                      <Typography variant="h6" sx={{ mb: 2 }}>
                        {service.title}
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                        {service.description}
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
              ))}
            </Grid>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default HomePage;
