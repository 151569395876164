import { createSlice } from "@reduxjs/toolkit";

const DashboardSlice = createSlice({
  name: "DashboardSlice",
  initialState: {
    task: [],
  },
  reducers: {
    addTask: (state, action) => {
      state.task.push(action.payload);
    },
  },
});

export const { addTask } = DashboardSlice.actions;
export const DashboardReducer = DashboardSlice.reducer;
