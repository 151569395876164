import React, { useState } from 'react';
import {
  Toolbar,
  Button,
  Menu,
  MenuItem,
  Box,
  Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import LoginRoundedIcon from '@mui/icons-material/LoginRounded';
import CalendarMonthRoundedIcon from '@mui/icons-material/CalendarMonthRounded';
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded';
import { companyLogo, menuItems } from '../../static/staticdata';
import { useNavigate } from 'react-router-dom';



const StyledToolbar = styled(Toolbar)({
  justifyContent: 'space-between',
});

const MenuButton = styled(Button)(({ theme }) => ({
  color: 'white',
  textTransform: 'none',
  '&:hover': {
    backgroundColor: 'rgba(255, 255, 255, 0.08)',
  },
}));

const Header = () => {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState({
    products: null,
    company: null,
  });

  const handleMenuOpen = (event, menu) => {
    setAnchorEl(prev => ({ ...prev, [menu]: event.currentTarget }));
  };

  const handleMenuClose = (menu) => {
    setAnchorEl(prev => ({ ...prev, [menu]: null }));
  };

  return (
    <>
      <Box sx={{ borderBottom: "1px solid #e5e5e5", boxShadow: "0rem 0rem 1rem #00000015", zIndex: 99 }}>
        <StyledToolbar>
          <Box sx={{ height: '2rem' }}>
            <img
              src={companyLogo}
              alt="Company Logo"
              style={{
                height: '100%',
                width: '100%',
                objectFit: 'contain',
              }}
              onError={(e) => {
                e.onerror = null;
                e.target.src = require("../../assets/nav-lexmom.webp");
              }}
            />
          </Box>
          <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
            <Box>
              <MenuButton

                endIcon={<ExpandMoreRoundedIcon />}
                onClick={(e) => handleMenuOpen(e, 'products')}
                sx={{ color: "primary.main" }}
              >
                Products
              </MenuButton>
              <Menu
                anchorEl={anchorEl.products}
                open={Boolean(anchorEl.products)}
                onClose={() => handleMenuClose('products')}
                MenuListProps={{
                  'aria-labelledby': 'products-button',
                }}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'center',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'center',
                }}
              >
                {menuItems.products.map((item) => (
                  <MenuItem
                    key={item.title}
                    onClick={() => handleMenuClose('products')}
                    component="a"
                    href={item.link}
                  >
                    <Typography variant="body2">{item.title}</Typography>
                  </MenuItem>
                ))}
              </Menu>
            </Box>
            <Box>
              <MenuButton
                endIcon={<ExpandMoreRoundedIcon />}
                onClick={(e) => handleMenuOpen(e, 'company')}
                sx={{ color: "primary.main" }}
              >
                Company
              </MenuButton>
              <Menu
                anchorEl={anchorEl.company}
                open={Boolean(anchorEl.company)}
                onClose={() => handleMenuClose('company')}
                MenuListProps={{
                  'aria-labelledby': 'company-button',
                }}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'center',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'center',
                }}
              >
                {menuItems.company.map((item) => (
                  <MenuItem
                    key={item.title}
                    onClick={() => handleMenuClose('company')}
                    component="a"
                    href={item.link}
                  >
                    <Typography variant="body2">{item.title}</Typography>
                  </MenuItem>
                ))}
              </Menu>
            </Box>
            <Button variant='outlined'
              startIcon={<CalendarMonthRoundedIcon />}
            >
              Demo
            </Button>
            <Button
              variant="contained"
              onClick={() => navigate("/login")}
              endIcon={<LoginRoundedIcon />}
            >
              Log in
            </Button>
          </Box>
        </StyledToolbar>
      </Box></>
  );
};

export default Header;