import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { toast } from "react-toastify";
import { backend_api } from "../../../../static/server";

export const getAllTags = createAsyncThunk(
  "getAllTags",
  async ({ url, token }) => {
    try {
      const res = await axios.get(url, {
        headers: {
          Authorization: `Token ${token}`,
          "Content-Type": "application/json",
        },
      });
      return res.data;
    } catch (error) {
      toast.error("Failed to get all tags.");
      throw error;
    }
  }
);

export const getAllRols = createAsyncThunk("getAllRols", async (url) => {
  try {
    const res = await axios.get(url);
    // console.log("allrols:", res);
    return res.data;
  } catch (error) {
    toast.error("Failed to get all roles.");
  }
});

export const getAllFiltercontact = createAsyncThunk(
  "getAllFiltercontact",
  async ({ url, token }, { rejectWithValue }) => {
    try {
      const res = await axios.get(url, {
        headers: {
          Authorization: `Token ${token}`,
          "Content-Type": "application/json",
        },
      });
      return res.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || "Failed to fetch contact");
    }
  }
);

export const getContactDetail = createAsyncThunk(
  "getContactDetail",
  async ({ url, token }) => {
    try {
      const res = await axios.get(url, {
        headers: {
          Authorization: `Token ${token}`,
        },
      });
      return res.data;
    } catch (error) {
      toast.error("Failed to get all roles.");
    }
  }
);

const contactSlice = createSlice({
  name: "contactSlice",
  initialState: {
    allRols: null,
    allTags: null,
    filteredContact: null,
    contactDetail: null,
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAllRols?.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getAllRols.fulfilled, (state, action) => {
        state.loading = false;
        state.allRols = action?.payload?.data;
      })
      .addCase(getAllRols.rejected, (state, action) => {
        state.lool = false;
        state.error = action.error.message || "Failed to fetch roles";
      })
      .addCase(getAllTags?.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getAllTags.fulfilled, (state, action) => {
        state.loading = false;
        state.allTags = action?.payload;
      })
      .addCase(getAllTags.rejected, (state, action) => {
        state.lool = false;
        state.error = action?.error?.message || "Failed to fetch Tags";
      })
      .addCase(getAllFiltercontact.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getAllFiltercontact.fulfilled, (state, action) => {
        state.loading = false;
        state.filteredContact = action?.payload?.data;
      })
      .addCase(getAllFiltercontact.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || "Failed to fetch contacts";
      })
      .addCase(getContactDetail?.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getContactDetail.fulfilled, (state, action) => {
        state.loading = false;
        state.contactDetail = action?.payload?.data;
      })
      .addCase(getContactDetail.rejected, (state, action) => {
        state.lool = false;
        state.error = action.error.message || "Failed to fetch contact detail";
      });
  },
});

export const ContactReducer = contactSlice.reducer;
