import { SiFiles } from "react-icons/si";
import { RiContactsBook3Fill } from "react-icons/ri";
import { GoClockFill } from "react-icons/go";
import { FaMoneyBillTransfer } from "react-icons/fa6";
import { MdAccountBalance } from "react-icons/md";
import { IoDocuments } from "react-icons/io5";
import { FaComments } from "react-icons/fa";
import { IoMdAnalytics } from "react-icons/io";
import { RiAppsLine } from "react-icons/ri";
import { FaCalendarDays } from "react-icons/fa6";
import { IoSettings } from "react-icons/io5";
import { IoHome } from "react-icons/io5";
import { BsListTask } from "react-icons/bs";
import { BiSolidCustomize } from "react-icons/bi";
import { lazy } from "react";

export const companyLogo = lazy(() => import("../assets/nav-lexmom.webp"));
export const userData = JSON.parse(localStorage.getItem("UserId"));

export const menuItems = {
  products: [
    { title: "Product Overview", link: "/product1" },
    { title: "Prosecution Manager", link: "/product2" },
    { title: "Lexmom Plus", link: "/product3" },
    { title: "Invention Manager", link: "/product3" },
    { title: "Pricing", link: "/product3" },
    { title: "Free Trial", link: "/product3" },
  ],

  company: [
    { title: "About Us", link: "/about-us" },
    { title: "Why Lexmom", link: "/careers" },
    { title: "Career", link: "/investors" },
    { title: "Blog", link: "/investors" },
    { title: "Forum", link: "/investors" },
    { title: "Testimonials", link: "/investors" },
    { title: "Learning Center", link: "/investors" },
  ],
};

export const validateEmail = (email) => {
  const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return regex.test(email);
};
export const roles = [
  "Account Administrator",
  "Account Administrator (backup)",
  "Assignee",
  "Associate",
  "Attorney",
  "Client",
  "Client/Solo Inventor",
  "Company",
  "Consultant",
  "Corporate Counsel",
  "Docketing Manager",
  "Employee",
  "Foreign Associate",
  "Inventor",
  "Law Firm",
  "Legal Assistant",
  "Legal Representative",
  "Manager",
  "Of Counsel",
  "Other",
  "Outside Counsel",
  "Paralegal",
  "Party of Interest",
  "Patent Agent",
  "Patent Attorney",
  "Patent Engineer",
  "Principal",
];
export const tags = ["friend", "son"];

export const DashboardSideMenu = [
  { mIcon: <IoHome />, mText: "Dashboard", path: "/dashboard" },

  {
    mIcon: <FaCalendarDays />,
    mText: "Calendar",
    path: "/dashboard/calendars",
  },
  { mIcon: <BsListTask />, mText: "Tasks", path: "/dashboard/tasks" },
  { mIcon: <SiFiles />, mText: "Cases", path: "/dashboard/cases" },
  {
    mIcon: <RiContactsBook3Fill />,
    mText: "Contacts",
    path: "/dashboard/contact-details",
  },
  {
    mIcon: <GoClockFill />,
    mText: "Activities",
    path: "/dashboard/activities",
  },
  {
    mIcon: <FaMoneyBillTransfer />,
    mText: "Billing",
    path: "/dashboard/bills",
  },
  {
    mIcon: <MdAccountBalance />,
    mText: "Accounts",
    path: "/dashboard/bank_accounts",
  },
  { mIcon: <IoDocuments />, mText: "Documents", path: "/dashboard/documents" },
  {
    mIcon: <FaComments />,
    mText: "Communications",
    path: "/dashboard/communications",
  },
  { mIcon: <IoMdAnalytics />, mText: "Reports", path: "/dashboard/reports" },
  {
    mIcon: <RiAppsLine />,
    mText: "App Integrations",
    path: "/dashboard/app_integrations",
  },
  {
    mIcon: <BiSolidCustomize />,
    mText: "Custom Fields",
    path: "/dashboard/custom_fields",
  },
  { mIcon: <IoSettings />, mText: "Settings", path: "/dashboard/settings" },
];

export const personalDashBillData = [
  { title: "Draft Bills", num: 0, btnText: "create new bill" },
  { title: "Total in Draft ", num: 0 },
  { title: "Unpaid Bills", num: 0 },
  { title: "Total in Unpaid ", num: 0 },
  { title: "Overdue Bills", num: 0 },
  { title: "Total in Overdue", num: 0 },
];

export const personalDashOtherData = [
  { title: "Total Client", DataCout: 0 },
  { title: "Total Contacts", DataCout: 0 },
  { title: "Total Cases", DataCout: 0 },
  { title: "Pending Cases", DataCout: 0 },
];

export const genders = ["male", "female", "other"];

export const columns = [
  {
    field: "id",
    headerName: "ID",
    width: 100,
  },
  {
    field: "firstName",
    headerName: "First Name",
    width: 130,
  },
  {
    field: "lastName",
    headerName: "Last Name",
    width: 130,
  },
  {
    field: "age",
    headerName: "Age",
    width: 100,
  },
  {
    field: "email",
    headerName: "Email",
    width: 200,
  },
  {
    field: "phone",
    headerName: "Phone",
    width: 150,
  },
  {
    field: "address",
    headerName: "Address",
    width: 250,
  },
  {
    field: "city",
    headerName: "City",
    width: 130,
  },
  {
    field: "country",
    headerName: "Country",
    width: 130,
  },
  {
    field: "occupation",
    headerName: "Occupation",
    width: 180,
  },
  {
    field: "department",
    headerName: "Department",
    width: 150,
  },
  {
    field: "salary",
    headerName: "Salary",
    width: 120,
  },
  {
    field: "startDate",
    headerName: "Start Date",
    width: 130,
  },
  {
    field: "status",
    headerName: "Status",
    width: 120,
  },
  {
    field: "rating",
    headerName: "Rating",
    width: 120,
  },
];

export const rows = [
  {
    id: 1,
    firstName: "John",
    lastName: "Snow",
    age: 30,
    email: "john.snow@email.com",
    phone: "(555) 123-4567",
    address: "123 Winter Street",
    city: "Winterfell",
    country: "Westeros",
    occupation: "Lord Commander",
    department: "Night's Watch",
    salary: 75000,
    startDate: "2020-01-15",
    status: "Active",
    rating: 4.8,
  },
  {
    id: 2,
    firstName: "Daenerys",
    lastName: "Targaryen",
    age: 28,
    email: "dany.t@email.com",
    phone: "(555) 234-5678",
    address: "456 Dragon Road",
    city: "Meereen",
    country: "Essos",
    occupation: "Queen",
    department: "Leadership",
    salary: 120000,
    startDate: "2019-06-20",
    status: "Active",
    rating: 4.9,
  },
  {
    id: 3,
    firstName: "Tyrion",
    lastName: "Lannister",
    age: 39,
    email: "tyrion.l@email.com",
    phone: "(555) 345-6789",
    address: "789 Lion's Path",
    city: "King's Landing",
    country: "Westeros",
    occupation: "Hand of the Queen",
    department: "Advisory",
    salary: 95000,
    startDate: "2018-12-10",
    status: "Active",
    rating: 4.7,
  },
  {
    id: 4,
    firstName: "Arya",
    lastName: "Stark",
    age: 20,
    email: "arya.stark@email.com",
    phone: "(555) 456-7890",
    address: "321 Wolf Road",
    city: "Braavos",
    country: "Essos",
    occupation: "Assassin",
    department: "Special Ops",
    salary: 85000,
    startDate: "2021-03-25",
    status: "Active",
    rating: 4.6,
  },
  {
    id: 5,
    firstName: "Cersei",
    lastName: "Lannister",
    age: 45,
    email: "cersei.l@email.com",
    phone: "(555) 567-8901",
    address: "654 Crown Street",
    city: "King's Landing",
    country: "Westeros",
    occupation: "Queen Regent",
    department: "Leadership",
    salary: 150000,
    startDate: "2017-08-30",
    status: "Inactive",
    rating: 3.5,
  },
];

export const taskFilter = [
  "All dates",
  "Past due",
  "Today",
  "Tomorrow",
  "This week",
  "This month",
  "This year",
  "No due date",
];

// export const custom_fieldData = [

//   {
//     leable: "Name",
//     type: "text",
//     require: true,
//     validation: "Max lenth 25",
//   },
//   {
//     leable: "Name",
//     type: "text",
//     require: true,
//     validation: "Max lenth 25",
//   },
//   {
//     leable: "Name",
//     type: "text",
//     require: true,
//     validation: "Max lenth 25",
//   },
//   {
//     leable: "Name",
//     type: "text",
//     require: true,
//     validation: "Max lenth 25",
//   },
// ];
