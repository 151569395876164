import React from "react";
import { Box, Typography } from "@mui/material";

const RetroGrid = () => {
  return (
    <Box className="retro-grid-container">
      <Box className="retro-grid-background" />
      <Box className="retro-grid" />
    </Box>
  );
};

const RetroGridBg = () => {
  return (
    <Box>
      <RetroGrid />
    </Box>
  );
};

export default RetroGridBg;
