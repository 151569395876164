import React, { useEffect, useState } from "react";
import {
  Box,
  Avatar,
  Menu,
  MenuItem,
  ListItemIcon,
  Divider,
  IconButton,
  Typography,
  Tooltip,
  InputBase,
} from "@mui/material";
import { FaSignOutAlt } from "react-icons/fa";
import { MdOutlineMenu, } from "react-icons/md";
import NotificationsActiveRoundedIcon from '@mui/icons-material/NotificationsActiveRounded';
import SettingsRoundedIcon from '@mui/icons-material/SettingsRounded';
import { AiOutlineSearch } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import { getFirstCharacters } from "../../../styles/styles";

import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../../redux/Apps/users/userSlice";
import { companyLogo } from "../../../static/staticdata";

const Navbar = ({ handleDrawerOpen }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [searchTerm, setSearchTerm] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);
  const [userData, setUserData] = useState(null);
  const [firstChar, setFirstChar] = useState(null);
  const open = Boolean(anchorEl);


  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleLogout = () => {
    navigate("/login");
    handleClose();
    dispatch(logout());
    localStorage.removeItem("UserId");
  }


  useEffect(() => {
    const storedUserData = JSON.parse(localStorage.getItem("UserId"));
    if (storedUserData) {
      setUserData(storedUserData);
      setFirstChar(getFirstCharacters(storedUserData.emailId));
    } else {
      setFirstChar(null);
    }
  }, []);

  return (
    <Box sx={{ width: '100%', bgcolor: "primary.main", p: "0.5rem 1rem" }}>
      <Box sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between'
      }}>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
          <Box sx={{ height: '2rem' }}>
            <img
              src={companyLogo}
              alt="Company Logo"
              style={{
                height: '100%',
                width: '100%',
                objectFit: 'contain',
                filter: "brightness(0) invert(1)"
              }}
              onError={(e) => {
                e.onerror = null;
                e.target.src = require("../../../assets/nav-lexmom.webp");
              }}
            />
          </Box>
        </Box>

        <Box sx={{ width: '50%', position: 'relative' }}>
          <InputBase
            fullWidth
            placeholder="Search Product..."
            value={searchTerm}
            onChange={handleSearchChange}
            sx={{
              bgcolor: 'white',
              borderRadius: 2,
              px: 2,
              height: 50,
            }}
            endAdornment={<AiOutlineSearch size={30} />}
          />
        </Box>

        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Tooltip title="Notifications">
            <IconButton color="white">
              <NotificationsActiveRoundedIcon sx={{ fontSize: "1.6rem" }} />
            </IconButton>
          </Tooltip>

          <Tooltip title="Settings">
            <IconButton color="white">
              <SettingsRoundedIcon sx={{ fontSize: "1.6rem" }} />
            </IconButton>
          </Tooltip>

          <Tooltip title="Account settings">
            <IconButton
              onClick={handleClick}
              sx={{ ml: 2 }}
            >
              <Avatar sx={{ width: 32, height: 32 }}>{firstChar}</Avatar>
            </IconButton>
          </Tooltip>

          <Menu
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            PaperProps={{
              elevation: 4,
              sx: {
                mt: 1.5,
                minWidth: 200,
              },
            }}
          >
            <MenuItem onClick={handleClose}>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                <Avatar />
                <Typography variant="body1">Profile</Typography>
              </Box>
            </MenuItem>

            <Divider />

            <MenuItem
              onClick={handleLogout}
            >
              <ListItemIcon>
                <FaSignOutAlt />
              </ListItemIcon>
              <Typography variant="body1">Logout</Typography>
            </MenuItem>
          </Menu>
        </Box>
      </Box>
    </Box>
  );
};

export default Navbar;