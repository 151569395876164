import {
  Box,
  Button,
  Dialog,
  Divider,
  FormControlLabel,
  IconButton,
  InputAdornment,
  MenuItem,
  Paper,
  Select,
  Slide,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import styles, { lebaleStyle, pageScrollStyle } from "../styles/styles";
import FilterListRoundedIcon from "@mui/icons-material/FilterListRounded";
import BorderColorRoundedIcon from "@mui/icons-material/BorderColorRounded";
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
import { useForm, Controller } from "react-hook-form";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import EmptyBox from "../assets/empty-box.webp";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const inputTypes = [
  "Case Select",
  "Contact Select",
  "text",
  "email",
  "number",
  "date",
  "select",
  "textarea",
];

const filterBtnsArr = [
  "All",
  "Task",
  "Cases",
  "Contacts",
  "Billing",
  "Account",
  "Document",
];

const columns = [
  { field: "leable", headerName: "Leable" },
  { field: "type", headerName: "Type" },
  { field: "required", headerName: "Required" },
  { field: "validation", headerName: "Validation" },
  {
    field: "action",
    headerName: "Action",
    renderCell: () => (
      <Box
        sx={{
          display: "flex",
          justifyContent: "start",
          alignItems: "center",
          gap: "0.2rem ",
        }}
      >
        <Tooltip title="Edit">
          <IconButton color="inherit" size="small" aria-label="edit">
            <BorderColorRoundedIcon sx={{ fontSize: "1rem" }} />
          </IconButton>
        </Tooltip>
        <Tooltip title="Delete">
          <IconButton color="inherit" size="small" aria-label="more">
            <DeleteRoundedIcon sx={{ fontSize: "1rem" }} />
          </IconButton>
        </Tooltip>
      </Box>
    ),
  },
];

const rows = [];

const CustomeFields = () => {
  const [optionCount, setOptionCount] = useState(2);
  const [optionLenError, setOptionLenError] = useState(false);
  const [showDash, setShowDash] = useState(0);
  const [addFieldOpenDilog, setAddFieldOpenDilog] = useState(false);

  const {
    handleSubmit,
    control,
    reset,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: {
      selected_form: "",
      field_name: "",
      field_type: "",
      options: [],
      validation: false,
      required: false,
      default_apply: false,
    },
  });
  const field_type = watch("field_type");
  const options = watch("options");

  const onSubmit = (data) => {
    console.log(data);
    handleClose();
    // reset({
    //   selected_form: "",
    //   field_name: "",
    //   field_type: "",
    //   validation: false,
    //   required: false,
    //   default_apply: false,
    // });
    reset();
  };

  const handleClickOpen = () => {
    setAddFieldOpenDilog(true);
  };

  const handleClose = () => {
    reset({
      selected_form: "",
      field_name: "",
      field_type: "",
      validation: false,
      required: false,
      default_apply: false,
    });
    setAddFieldOpenDilog(false);
  };

  const getBtnActiveStyle = (key) => ({
    textTransform: "capitalize",
    transition: "0.3s",
    borderBottom: showDash === key ? "2px solid #00B3B0" : "none",
    borderRadius: "0rem",
  });

  const handleDeleteOption = (i) => {
    console.log(options, typeof options, "hey", i);

    options.splice(i, 1);

    if (optionCount === 2) {
      setOptionLenError(true);
    } else {
      setOptionCount(optionCount - 1);
    }
    console.log("deleted");
  };

  return (
    <>
      <div className="w-full">
        <Box sx={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
          <div className="flex justify-between bg-white rounded-lg items-center shadow-md p-3">
            <h1 className={`${styles.title}`}>
              {`${filterBtnsArr[showDash]}`} Custom Fields
            </h1>
            <Box className="flex gap-4">
              <Button variant="outlined" sx={{ textTransform: "capitalize" }}>
                Modify Order
              </Button>
              <Button
                variant="contained"
                sx={{ textTransform: "capitalize" }}
                onClick={() => handleClickOpen()}
              >
                Add field
              </Button>
            </Box>
          </div>

          <Box
            className=" bg-white rounded-lg justify-between h-[80vh] p-2 w-full"
            sx={{
              ...pageScrollStyle,
              overflow: "auto",
            }}
          >
            <Box sx={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  gap: "0.8rem",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: "0.8rem",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      gap: "0.5rem",
                      p: "0.2rem",
                      borderRadius: "0.5rem",
                    }}
                  >
                    {filterBtnsArr.map((item, i) => (
                      <Button
                        key={i}
                        size="small"
                        variant="text"
                        sx={getBtnActiveStyle(i)}
                        onClick={() => setShowDash(i)}
                      >
                        {item}
                      </Button>
                    ))}
                  </Box>
                  <Box>
                    <TextField
                      size="small"
                      placeholder="Search task"
                      className="flex-1"
                      label="Search"
                      sx={{ width: "15rem" }}
                    />
                  </Box>
                </Box>
                <Button
                  variant="outlined"
                  color="primary"
                  startIcon={<FilterListRoundedIcon />}
                >
                  Filter
                </Button>
              </Box>

              <Box>
                <TableContainer component={Paper}>
                  <Table sx={{ width: "100%" }} aria-label="simple table">
                    <TableHead>
                      <TableRow
                        sx={{
                          backgroundColor: "#f0f0f0",
                        }}
                      >
                        {columns.map((item, i) => (
                          <TableCell key={i} align="left">
                            {item.headerName}
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {rows && rows.length > 0 ? (
                        rows.map((item, i) => (
                          <TableRow
                            key={i}
                            sx={{
                              "&:last-child td, &:last-child th": { border: 0 },
                            }}
                          >
                            {columns.map((col, j) => (
                              <TableCell key={j} align="left">
                                {col.renderCell
                                  ? col.renderCell(item)
                                  : item[col.field]}
                              </TableCell>
                            ))}
                          </TableRow>
                        ))
                      ) : (
                        <TableRow>
                          <TableCell colSpan={columns.length} sx={{ p: 0 }}>
                            <Box
                              sx={{
                                height: "20rem",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                gap: "1rem",
                                width: "100%",
                                flexDirection: "column",
                              }}
                            >
                              <Box sx={{ height: "10rem" }}>
                                <img
                                  src={EmptyBox}
                                  alt="No Data"
                                  style={{
                                    height: "100%",
                                    width: "100%",
                                    objectFit: "contain",
                                  }}
                                  onError={(e) => {
                                    e.onerror = null;
                                    e.target.src = require("../assets/no-preview.webp");
                                  }}
                                />
                              </Box>
                              <Typography
                                variant="body1"
                                sx={{ fontSize: "1rem", fontWeight: 500 }}
                              >
                                No Data Found
                              </Typography>
                              <Button
                                size="small"
                                variant="contained"
                                color="primary"
                                onClick={() => handleClickOpen()}
                              >
                                Add field
                              </Button>
                            </Box>
                          </TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
            </Box>
          </Box>
        </Box>
      </div>

      {/* dialog for adding custom field */}
      <Dialog
        open={addFieldOpenDilog}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "1rem",
            p: "1rem",
          }}
        >
          <Box>
            <Typography variant="body1" sx={{ fontSize: "1.5rem" }}>
              Add Custom Field
            </Typography>
          </Box>
          <Box sx={{ width: "30rem" }}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Box
                sx={{ display: "flex", gap: "1rem", flexDirection: "column" }}
              >
                <Box>
                  <label htmlFor="selected_form" style={lebaleStyle}>
                    Select Form:
                  </label>
                  <Controller
                    name="selected_form"
                    control={control}
                    rules={{ required: true }}
                    render={({ field }) => (
                      <Select
                        {...field}
                        size="small"
                        fullWidth
                        onChange={(e) => {
                          field.onChange(e);
                          setShowDash(filterBtnsArr.indexOf(e.target.value));
                        }}
                      >
                        {filterBtnsArr.slice(1).map((item, i) => (
                          <MenuItem key={i} value={item}>
                            {item}
                          </MenuItem>
                        ))}
                      </Select>
                    )}
                  />
                  {errors.selected_form && (
                    <Typography color="error" sx={{ fontSize: "0.8rem" }}>
                      This field is required
                    </Typography>
                  )}
                </Box>
                <Box>
                  <label htmlFor="field_name" style={lebaleStyle}>
                    Field Name:
                  </label>
                  <Controller
                    name="field_name"
                    control={control}
                    rules={{ required: true }}
                    render={({ field }) => (
                      <TextField {...field} fullWidth size="small" />
                    )}
                  />
                  {errors.field_name && (
                    <Typography color="error" sx={{ fontSize: "0.8rem" }}>
                      This field is required
                    </Typography>
                  )}
                </Box>
                <Box>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <label htmlFor="field_type" style={lebaleStyle}>
                      Type:
                    </label>
                    {optionLenError ? (
                      <Typography color="error" sx={{ fontSize: "0.8rem" }}>
                        This type contain minimum 2 options.
                      </Typography>
                    ) : null}
                  </Box>
                  <Controller
                    name="field_type"
                    control={control}
                    rules={{ required: true }}
                    render={({ field }) => (
                      <Select {...field} size="small" fullWidth>
                        {inputTypes.map((item, i) => (
                          <MenuItem key={i} value={item}>
                            {item}
                          </MenuItem>
                        ))}
                      </Select>
                    )}
                  />
                  {errors.field_type && (
                    <Typography color="error" sx={{ fontSize: "0.8rem" }}>
                      This field is required
                    </Typography>
                  )}
                  {field_type === "select" ? (
                    <Box
                      sx={{
                        mt: "0.5rem",
                        display: "flex",
                        justifyContent: "center ",
                        alignItems: "center",
                        gap: "1rem",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          gap: "0.5rem",
                          width: "100%",
                          maxHeight: "6rem",
                          overflowX: "auto",
                          ...pageScrollStyle,
                        }}
                      >
                        {[...Array(optionCount)].map((item, i) => (
                          <Controller
                            key={i}
                            name={`options.${i}`}
                            control={control}
                            rules={{ required: true }}
                            render={({ field }) => (
                              <TextField
                                {...field}
                                fullWidth
                                size="small"
                                placeholder={`option${i + 1}`}
                                value={field.value || ""}
                                slotProps={{
                                  input: {
                                    endAdornment: (
                                      <InputAdornment position="end">
                                        <Tooltip title="delete">
                                          <IconButton
                                            onClick={() =>
                                              handleDeleteOption(i)
                                            }
                                          >
                                            <DeleteRoundedIcon
                                              sx={{ fontSize: "1.3rem " }}
                                            />
                                          </IconButton>
                                        </Tooltip>
                                      </InputAdornment>
                                    ),
                                  },
                                }}
                              />
                            )}
                          />
                        ))}
                        {errors.options && (
                          <Typography color="error" sx={{ fontSize: "0.8rem" }}>
                            These field is required
                          </Typography>
                        )}
                      </Box>
                      <Tooltip title="Add options">
                        <IconButton
                          color="primary"
                          onClick={() => {
                            setOptionCount(optionCount + 1);
                            setOptionLenError(false);
                          }}
                        >
                          <AddRoundedIcon />
                        </IconButton>
                      </Tooltip>
                    </Box>
                  ) : null}
                </Box>
                <Box>
                  <Controller
                    name="required"
                    control={control}
                    render={({ field }) => (
                      <FormControlLabel
                        control={
                          <input
                            type="checkbox"
                            checked={field.value}
                            onChange={(e) => field.onChange(e.target.checked)}
                            className="ml-3 mr-2"
                          />
                        }
                        label={
                          <Typography
                            sx={{ fontSize: "0.9rem", fontWeight: 400 }}
                          >
                            Required
                          </Typography>
                        }
                      />
                    )}
                  />
                  <Controller
                    name="validation"
                    control={control}
                    render={({ field }) => (
                      <FormControlLabel
                        control={
                          <input
                            type="checkbox"
                            checked={field.value}
                            onChange={(e) => field.onChange(e.target.checked)}
                            className="ml-3 mr-2"
                          />
                        }
                        label={
                          <Typography
                            sx={{ fontSize: "0.9rem", fontWeight: 400 }}
                          >
                            Validation
                          </Typography>
                        }
                      />
                    )}
                  />
                </Box>
                <Divider orientation="horizontal" flexItem />
                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                  <Controller
                    name="default_apply"
                    control={control}
                    render={({ field }) => (
                      <FormControlLabel
                        control={
                          <input
                            type="checkbox"
                            checked={field.value}
                            onChange={(e) => field.onChange(e.target.checked)}
                            className="ml-3 mr-2"
                          />
                        }
                        label={
                          <Typography
                            sx={{ fontSize: "0.9rem", fontWeight: 400 }}
                          >
                            Default apply on form
                          </Typography>
                        }
                      />
                    )}
                  />
                  <Box>
                    <Button
                      type="submit"
                      variant="contained"
                      size="small"
                      color="primary"
                    >
                      Create
                    </Button>
                  </Box>
                </Box>
              </Box>
            </form>
          </Box>
        </Box>
      </Dialog>
    </>
  );
};

export default CustomeFields;
