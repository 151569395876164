import { combineReducers } from "redux";
import { persistedReducer } from "./Apps/users/userSlice";
import { DashboardReducer } from "./Apps/Lawyerapp/DashboardSlice/DashboardSlice";
import { ContactReducer } from "./Apps/Lawyerapp/ContactSlice/ContactSlice";

const rootReducer = combineReducers({
  user: persistedReducer,
  dashboard: DashboardReducer,
  contact: ContactReducer,
});

export default rootReducer;
