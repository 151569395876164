import React from "react";
import {
  Box,
  Button,
  IconButton,
  TextField,
  Typography,
  styled,
} from "@mui/material";
import InstagramIcon from "@mui/icons-material/Instagram";
import FacebookIcon from "@mui/icons-material/Facebook";

import { Controller, useForm } from "react-hook-form";

const CircularForm = styled(Box)(({ theme }) => ({
  width: 500,
  height: 500,
  borderRadius: "50%",
  backgroundColor: "#f3e5f5",
  padding: theme.spacing(8),
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  position: "absolute",
  top: "-250px", // Half the height to make it overlap
  left: "50%",
  transform: "translateX(-50%)",
  zIndex: 1,
  boxShadow: "0 4px 20px rgba(0,0,0,0.1)",
  [theme.breakpoints.down("sm")]: {
    width: 300,
    height: 300,
    padding: theme.spacing(4),
    top: "-150px",
  },
}));

const FooterSection = styled(Box)(({ theme }) => ({
  backgroundColor: "#004242",
  color: "white",
  position: "relative",
  marginTop: "15.625rem",
  padding: "17.5rem 0 2.5rem",
  [theme.breakpoints.down("sm")]: {
    marginTop: "150px",
    padding: "180px 0 40px",
  },
}));

const Layout = () => {
  const {
    handleSubmit,
    control,
    reset,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: {},
  });

  return (
    <Box sx={{ minHeight: "100vh" }}>
      <FooterSection>
        <Box sx={{ p: "2rem" }}>
          <CircularForm>
            <Typography
              variant="h6"
              align="center"
              sx={{ color: "#004242", mb: 2 }}
            >
              Any questions? We'd love to hear from you!
            </Typography>
            <form>
              <Box
                sx={{
                  display: "flex",
                  gap: "1rem",
                  flexDirection: "column",
                  width: "25rem",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Box sx={{ display: "flex", gap: "0.5rem" }}>
                  <Controller
                    name="first_name"
                    control={control}
                    rules={{ required: true }}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        fullWidth
                        size="small"
                        type="text"
                        label="First Name"
                        sx={{
                          "& .MuiOutlinedInput-root": {
                            borderRadius: "50px",
                            p: "0",
                            backgroundColor: "transparent",
                            "& fieldset": {
                              border: "2px solid #004242",
                            },
                            "&:hover fieldset": {
                              borderColor: "#007777",
                            },
                            "&.Mui-focused fieldset": {
                              borderColor: "#00aaaa",
                            },
                          },
                          "& .MuiOutlinedInput-input": {
                            p: "0.5rem 1rem",
                            color: "#004242",
                            "&::placeholder": {
                              color: "#004242",
                              opacity: 1,
                            },
                          },
                        }}
                      />
                    )}
                  />
                  {errors.first_name && (
                    <Typography color="error" sx={{ fontSize: "0.8rem" }}>
                      This field is required
                    </Typography>
                  )}
                  <Controller
                    name="last_name"
                    control={control}
                    rules={{ required: true }}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        fullWidth
                        size="small"
                        type="text"
                        label="Last Name"
                        sx={{
                          "& .MuiOutlinedInput-root": {
                            borderRadius: "50px",
                            p: "0",
                            backgroundColor: "transparent",
                            "& fieldset": {
                              border: "2px solid #004242",
                            },
                            "&:hover fieldset": {
                              borderColor: "#007777",
                            },
                            "&.Mui-focused fieldset": {
                              borderColor: "#00aaaa",
                            },
                          },
                          "& .MuiOutlinedInput-input": {
                            p: "0.5rem 1rem",
                            color: "#004242",
                            "&::placeholder": {
                              color: "#004242",
                              opacity: 1,
                            },
                          },
                        }}
                      />
                    )}
                  />
                  {errors.last_name && (
                    <Typography color="error" sx={{ fontSize: "0.8rem" }}>
                      This field is required
                    </Typography>
                  )}
                </Box>
                <Box>
                  <Controller
                    name="email"
                    control={control}
                    rules={{ required: true }}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        fullWidth
                        size="small"
                        type="email"
                        label="Email"
                        sx={{
                          width: "20rem",
                          "& .MuiOutlinedInput-root": {
                            borderRadius: "50px",
                            p: "0",
                            backgroundColor: "transparent",
                            "& fieldset": {
                              border: "2px solid #004242",
                            },
                            "&:hover fieldset": {
                              borderColor: "#007777",
                            },
                            "&.Mui-focused fieldset": {
                              borderColor: "#00aaaa",
                            },
                          },
                          "& .MuiOutlinedInput-input": {
                            p: "0.5rem 1rem",
                            color: "#004242",
                            "&::placeholder": {
                              color: "#004242",
                              opacity: 1,
                            },
                          },
                        }}
                      />
                    )}
                  />
                  {errors.email && (
                    <Typography color="error" sx={{ fontSize: "0.8rem" }}>
                      This field is required
                    </Typography>
                  )}
                </Box>
                <Box>
                  <Controller
                    name="Phone_no"
                    control={control}
                    rules={{ required: true }}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        size="small"
                        label="Phone Number"
                        sx={{
                          "& .MuiOutlinedInput-root": {
                            borderRadius: "50px",
                            p: "0",
                            backgroundColor: "transparent",
                            "& fieldset": {
                              border: "2px solid #004242",
                            },
                            "&:hover fieldset": {
                              borderColor: "#007777",
                            },
                            "&.Mui-focused fieldset": {
                              borderColor: "#00aaaa",
                            },
                          },
                          "& .MuiOutlinedInput-input": {
                            p: "0.5rem 1rem",
                            color: "#004242",
                            "&::placeholder": {
                              color: "#004242",
                              opacity: 1,
                            },
                          },
                        }}
                      />
                    )}
                  />
                  {errors.Phone_no && (
                    <Typography color="error" sx={{ fontSize: "0.8rem" }}>
                      This field is required
                    </Typography>
                  )}
                </Box>
                <Button
                  sx={{
                    backgroundColor: "#004242",
                    color: "white",
                    borderRadius: "50px",
                    padding: "8px 32px",
                    textTransform: "none",
                    "&:hover": {
                      backgroundColor: "#003333",
                    },
                  }}
                  variant="contained"
                >
                  Submit
                </Button>
              </Box>
            </form>
          </CircularForm>

          {/* Footer Links */}
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
              flexWrap: "wrap",
              gap: 4,
            }}
          >
            <Box>
              <Typography variant="body1">LOGIN</Typography>
              <Typography variant="body1">SIGN UP</Typography>
              <Box sx={{ mt: 4 }}>
                <Typography variant="body1">Product</Typography>
                <Typography variant="body1">Company</Typography>
                <Typography variant="body1">FAQ</Typography>
                <Typography variant="body1">Contact Us</Typography>
              </Box>
            </Box>

            <Box
              sx={{
                display: "flex",
                gap: "1rem",
                height: "2rem",
              }}
            >
              <IconButton color="inherit">
                <InstagramIcon />
              </IconButton>
              <IconButton color="inherit">
                <FacebookIcon />
              </IconButton>
            </Box>

            <Box>
              <Typography variant="body1">Blog</Typography>
              <Typography variant="body1">Terms</Typography>
              <Typography variant="body1">Accessibility</Typography>
            </Box>
          </Box>

          {/* Bottom Text */}
          <Typography
            variant="h3"
            sx={{
              color: "white",
              fontWeight: "300",
              textAlign: "center",
              mt: "2rem",
            }}
          >
            Your Gateway to Justice and Legal Clarity.
          </Typography>
        </Box>
      </FooterSection>
    </Box>
  );
};

export default Layout;
