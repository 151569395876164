import { createSlice } from "@reduxjs/toolkit";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

const userSlice = createSlice({
  name: "user",
  initialState: {
    token: null,
    user: null,
    lawerId: null,
    userId: null,
  },
  reducers: {
    setUser: (state, action) => {
      state.user = action.payload;
    },
    setToken: (state, action) => {
      state.token = action.payload;
    },
    setLawerId: (state, action) => {
      state.lawerId = action.payload;
    },
    setUserId: (state, action) => {
      state.userId = action.payload;
    },
    logout: (state) => {
      state.token = null;
      state.user = null;
    },
  },
});

export const { setUser, setToken, setLawerId, setUserId, logout } =
  userSlice.actions;

const persistConfig = {
  key: "root",
  storage,
  whitelist: ["token", "userId", "lawerId"],
};

export const persistedReducer = persistReducer(
  persistConfig,
  userSlice.reducer
);
